import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/system'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import Logo from './Logo'

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledLink = styled(Link)({
  color: 'inherit',
  margin: '10px',
  textDecoration: 'none',
  cursor: 'pointer',
})

function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const scrollToSection = (sectionId) => {
    window.location.href = `/#${sectionId}`
  }

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'primary.main' }}>
      <StyledToolbar>
        <Typography
          variant="h4"
          sx={{
            display: { xs: 'flex', md: 'block' },
            width: '50%',
            marginLeft: -5,
          }}
        >
          <RouterLink to={'/'}>
            <Logo />
          </RouterLink>
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <StyledLink to="/" onClick={() => scrollToSection('offer')}>
            <Typography variant="h6">Oferta</Typography>
          </StyledLink>
          <StyledLink to="/" onClick={() => scrollToSection('lekarze')}>
            <Typography variant="h6">Lekarze</Typography>
          </StyledLink>
          <StyledLink to="/" onClick={() => scrollToSection('kontakt')}>
            <Typography variant="h6">Kontakt</Typography>
          </StyledLink>
        </Box>
        <IconButton
          sx={{ display: { xs: 'flex', md: 'none' }, color: '#fff' }}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
      </StyledToolbar>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' } }}
      >
        <MenuItem onClick={handleClose}>
          <Link href="#offer" sx={{ textDecoration: 'none', color: 'black' }}>
            Oferta
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="#lekarze" sx={{ textDecoration: 'none', color: 'black' }}>
            Lekarze
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="#kontakt" sx={{ textDecoration: 'none', color: 'black' }}>
            Kontakt
          </Link>
        </MenuItem>
      </Menu>
    </AppBar>
  )
}

export default Navbar
