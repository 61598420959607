import { createTheme, responsiveFontSizes } from '@mui/material'

export let theme = createTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#0773f8',
      second: '#01abee',
    },
  },
})

theme = responsiveFontSizes(theme)
