import React from 'react'
import Navbar from './components/Navbar'
import Tools from './components/Tools'
import MainPage from './components/pages/MainPage/MainPage'
import NewsMobile from './components/pages/News/NewsMobile'
import PricingList from './components/pages/PricingList'
import Doctors from './components/pages/Doctors'
import Contact from './components/pages/Contact'
import Footer from './components/Footer'
import InformationMobile from './components/InformationMobile'

const App = () => {
  return (
    <React.Fragment>
      <Navbar />
      <MainPage />
      <InformationMobile />
      <NewsMobile />
      <Tools />
      <PricingList />
      <Doctors />
      <Contact />
      <Footer />
    </React.Fragment>
  )
}

export default App
