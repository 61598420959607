import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ClinicPolicy from './components/pages/ClinicPolicy'
import ScrollToTop from './helpers/ScrollToTop'

const root = ReactDOM.createRoot(document.getElementById('root'))

// const routes = [
//   {
//     path: "/",
//     element: <App />,
//   },
//   {
//     path: "regulamin",
//     element: <ClinicPolicy />,
//   },
// ];
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/regulamin" element={<ClinicPolicy />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
