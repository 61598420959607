import { Container, Typography, Grid } from '@mui/material'
import React from 'react'
import CardDoctor from '../CardDoctor'
import wave from '../../assets/wave.svg'

const Doctors = () => {
  return (
    <Container
      id="lekarze"
      maxWidth="false"
      sx={{
        backgroundImage: `url(${wave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container sx={{ marginTop: 4 }}>
        <Typography
          variant="h4"
          sx={{ display: 'flex', justifyContent: 'center', margin: 2 }}
        >
          Lekarze
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CardDoctor type="lekarz stomatolog" doctor="Magdalena Przysocka" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor
              type="chirurg stomatolog"
              doctor="Przemysław Przysocki"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor type="lekarz stomatolog" doctor="Dariusz Budziński" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor type="chirurg stomatolog" doctor="Grażyna Schubert" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor type="lekarz stomatolog" doctor="Ewa Piechowiak" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor
              type="lekarz stomatolog"
              doctor="Marta Przysocka-Pietrzak"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDoctor
              type="lekarz stomatolog"
              doctor="Marta Ostrowska-Donke"
            />
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default Doctors
