import { Paper, Typography } from '@mui/material'
import React from 'react'

const CardDoctor = ({ type, doctor }) => {
  return (
    <Paper elevation={3} sx={{ paddingBlock: 2, paddingInline: 4, margin: 1 }}>
      <Typography
        variant="subtitle2"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {type}
      </Typography>
      <Typography
        variant="h6"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {doctor}
      </Typography>
    </Paper>
  )
}

export default CardDoctor
