import React from 'react'
import Grid from '@mui/material/Grid'
import { Box, Container } from '@mui/system'
import News from '../News/News'
import { Typography } from '@mui/material'
import PhoneNumber from '../../PhoneNumber'
import { useWindowWidth } from '../../../helpers/WindowWidth'
import background from '../../../assets/galeria1-classic.jpg'
import background2 from '../../../assets/galeria1-mb.jpg'
//import backgroundMobile from "../../../assets/trident-main-mobile.jpg";

const MainPage = () => {
  const img = useWindowWidth() >= 1200 ? background : background2
  const imgHeight = useWindowWidth() >= 900 ? '100%' : '40vh'
  const imgPosition = useWindowWidth() >= 900 ? 'center' : 'top'
  //const contentHeight = useWindowWidth() >= 900 ? "50vh" : "auto";
  return (
    <Container
      maxWidth="false"
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: imgHeight,
        backgroundPosition: imgPosition,
      }}
    >
      <Grid
        container
        columnSpacing={2}
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          paddingBottom: '20px',
          paddingTop: '10px',
        }}
      >
        <Grid
          item
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          <Box
            sx={{
              marginBottom: 1,
              backgroundColor: 'rgba(7, 115, 248, 0.9 )',
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdrop: 'blur( 15px )',
              WebkitBackdropFilter: 'blur( 15px )',
              padding: 2,
              borderRadius: 4,
              color: '#fff',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Typography variant="subtitle2" sx={{ background: 'none' }}>
              Szanowni pacjenci! <br />
              Informujemy, że w dniu 16.08.2024 gabinety będą nieczynne
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              padding: 2,
              borderRadius: 4,
              color: '#fff',
              background: 'rgba( 255, 255, 255, 0.45 )',
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdropFilter: 'blur( 10px )',
              WebkitBackdropFilter: 'blur( 10px )',
              border: '1px solid rgba( 255, 255, 255, 0.18 )',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ color: 'secondary.main' }}
            >
              W celu rezerwacji wizyty prosimy dzwonić pod numer <br />
              <PhoneNumber />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: 'flex-end',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <News />
        </Grid>
      </Grid>
    </Container>
  )
}

export default MainPage
