import { useEffect } from 'react'
import { useLocation } from 'react-router'
import usePrevious from './CheckPrevious'

const ScrollToTop = (props) => {
  const location = useLocation()
  const previousLocation = usePrevious(location)

  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname) {
      window.scrollTo(0, 0)
    }
  }, [location, previousLocation])

  return <>{props.children}</>
}

export default ScrollToTop
