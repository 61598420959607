import { Typography, Box, Stack } from '@mui/material'
import React from 'react'
import ActionAreaCard from '../../CardNews'

import './News.css'
import news from '../../../data/news.json'

const News = () => {
  return (
    <Box
      className="scroll-container"
      sx={{
        width: '400px',
        padding: 1,
        overflow: 'scroll',
        background: 'rgba( 255, 255, 255, 0.55 )',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 11px )',
        WebkitBackdropFilter: 'blur( 11px )',
        borderRadius: '10px',
        border: '1px solid rgba( 255, 255, 255, 0.18 )',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: 1,
          display: 'flex',
          justifyContent: 'center',
          color: '#333',
        }}
      >
        Aktualności
      </Typography>
      <Stack direction="column">
        {news.map((i) => {
          return (
            <ActionAreaCard
              key={i.title}
              title={i.title}
              content={i.content}
              img={i.img}
              alt={i.alt}
            />
          )
        })}
      </Stack>
    </Box>
  )
}

export default News
