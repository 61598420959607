import React from 'react'
import { Typography, Container } from '@mui/material'
import { Stack } from '@mui/system'
import ActionAreaCardMobile from '../../CardNewsMobile'
import news from '../../../data/news.json'

const NewsMobile = () => {
  return (
    <Container
      sx={{
        display: { xs: 'block', md: 'none' },
        marginTop: 2,
        marginBottom: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: '#333',
        }}
      >
        Aktualnośći
      </Typography>
      <Container
        className="scroll-container"
        sx={{
          display: 'flex',
          overflow: 'scroll',
        }}
      >
        <Stack direction="row" spacing={2}>
          {news.map((i) => {
            return (
              <ActionAreaCardMobile
                key={i.title}
                title={i.title}
                content={i.content}
                img={i.img}
                alt={i.alt}
              />
            )
          })}
        </Stack>
      </Container>
    </Container>
  )
}

export default NewsMobile
