import { Box, Typography } from '@mui/material'

import { Container } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const date = new Date()

  return (
    <Container
      maxWidth="false"
      sx={{
        backgroundColor: 'secondary.main',
      }}
    >
      <Container
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <Link
            to={'/regulamin'}
            style={{
              textDecoration: 'none',
              color: '#fff',
            }}
          >
            <Typography>Regulamin</Typography>
          </Link>
        </Box>

        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#fff' }}>
            &copy; {date.getFullYear()} Gabinety Stomatologiczne Trident
          </Typography>
        </Box>
      </Container>
    </Container>
  )
}

export default Footer
