import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import CallIcon from "@mui/icons-material/Call";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneNumber from '../PhoneNumber'

const Contact = () => {
  return (
    <Grid id="kontakt" container spacing={3} sx={{ padding: 4 }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Gabinety Stomatologiczne TriDent</Typography>
          <Typography sx={{ marginTop: 1 }}>
            ul. Andrzeja Frycza-Modrzewskiego 1
          </Typography>
          <Typography>64-100 Leszno</Typography>
          <Typography>Poniedziałek - Piątek od 8:00 do 20:30</Typography>
          <PhoneNumber />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2465.370018907754!2d16.58460214357122!3d51.835937773007394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4705983700000001%3A0x3aa7f4e14b8ce946!2sTri+Dent.+Gabinety+stomatologiczne!5e0!3m2!1spl!2spl!4v1560180321619!5m2!1spl!2spl"
          width="100%"
          height="300px"
          frameBorder={0}
        ></iframe>
      </Grid>
    </Grid>
  )
}

export default Contact
