import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PhoneIcon from '@mui/icons-material/Phone'

const PhoneNumber = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'center',
        marginTop: 2,
      }}
    >
      <Button
        variant="outlined"
        startIcon={<PhoneIcon />}
        href="tel:+48655266786"
        sx={{
          color: 'secondary.main',
          borderColor: 'secondary.main',
          borderRadius: 5,
          padding: '5px 10px',

          '&:hover': {
            color: '#fff',
            backgroundColor: 'secondary.main',
            borderColor: 'secondary.main',
          },
        }}
      >
        65 526 67 86
      </Button>
      <Button
        variant="outlined"
        startIcon={<PhoneIcon />}
        href="tel:+48603603469"
        sx={{
          color: 'secondary.main',
          borderColor: 'secondary.main',
          borderRadius: 5,
          padding: '5px 10px',
          '&:hover': {
            color: '#fff',
            backgroundColor: 'secondary.main',
            borderColor: 'secondary.main',
          },
        }}
      >
        603 603 469
      </Button>
    </Stack>
  )
}

export default PhoneNumber
