import React from 'react'
import { styled } from '@mui/material'
import Navbar from '../Navbar'
import { Container, Typography } from '@mui/material'
import Footer from '../Footer'

const Title = styled(Typography)`
  margin-top: 12px;
  margin-bottom: 0px;
`

const Content = styled(Typography)`
  margin-bottom: 8px;
  text-align: justify;
`

const ClinicPolicy = () => {
  return (
    <>
      <Navbar />
      <Container
        fullWidth="false"
        sx={{
          paddingY: 4,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h1">
          Regulamin
        </Typography>
        <Title variant="h5" component="h2">
          1. Rezerwacja terminu wizyty
        </Title>
        <Content variant="body1">
          Rezerwując termin wizyty, Pacjent oświadcza, że zapoznał się z
          regulaminem gabinetów.
        </Content>
        <Title variant="h5" component="h2">
          2. Gabinety TriDent
        </Title>
        <Content variant="body1">
          Gabinety TriDent są prywatnym podmiotem leczniczym działającym na
          podstawie ustawy z dnia 15 kwietnia 2011 r. o działalności leczniczej.
        </Content>
        <Title variant="h5" component="h2">
          3. Umawianie wizyt
        </Title>
        <Content variant="body1">
          Przyjmujemy Pacjentów wyłącznie po wcześniejszym telefonicznym lub
          osobistym umówieniu terminu wizyty.
        </Content>
        <Title variant="h5" component="h2">
          4. Lista oczekująca
        </Title>
        <Content variant="body1">
          W pilnych przypadkach, w celu przyspieszenia terminu wizyty,
          proponujemy zapisanie się na listę oczekującą. Jeśli inny Pacjent
          zwolni miejsce lub powstanie rezerwa czasowa, potrzebna do
          przeprowadzenia wizyty, skontaktujemy się z Państwem.
        </Content>
        <Title variant="h5" component="h2">
          5. Pierwszeństwo dla zapisanych Pacjentów
        </Title>
        <Content variant="body1">
          Osoby zapisane na wizytę mają bezwzględne pierwszeństwo. Pacjenci z
          bólem/po urazie będą przyjmowani w chwili, gdy powstanie rezerwa
          czasowa, potrzebna na przeprowadzenie wizyty, lub gdy umówiony Pacjent
          odwoła swoją wizytę i zwolni termin.
        </Content>
        <Title variant="h5" component="h2">
          6. Rejestracja terminu wizyty
        </Title>
        <Content variant="body1">
          Do rejestracji terminu wizyty Pacjent zobowiązany jest podać swoje
          dane osobowe, takie jak: imię, nazwisko, numer PESEL i numer telefonu
          do kontaktu.
        </Content>
        <Title variant="h5" component="h2">
          7. Potwierdzenie wizyty
        </Title>
        <Content variant="body1">
          Każda wizyta musi zostać potwierdzona. Automatycznie, 24 godziny przed
          terminem wizyty zostaje wysłana wiadomość SMS zawierająca link, w
          który należy kliknąć i potwierdzić lub anulować wizytę. W przypadku
          braku reakcji ze strony Pacjenta, Rejestracja spróbuje skontaktować
          się z Państwem telefonicznie lub wysłać wiadomość SMS. Jeśli nie
          otrzymamy odpowiedzi i nie będziemy w stanie skontaktować się z
          Pacjentem, wizyta i ewentualne następne zaplanowane terminy zostaną
          anulowane.
        </Content>
        <Title variant="h5" component="h2">
          8. Rezygnacja z umówionej wizyty
        </Title>
        <Content variant="body1">
          Obowiązkiem Pacjenta jest powiadomienie Rejestracji o rezygnacji z
          umówionej wizyty co najmniej 12 godzin przed terminem. Dzięki temu
          Pacjent z listy oczekującej, np. z bólem/po urazie, będzie mógł
          skorzystać z tego terminu. Prosimy pamiętać, że następnym razem to
          Pan/Pani może być w potrzebie.
        </Content>
        <Title variant="h5" component="h2">
          9. Niezgłoszenie się na wizytę
        </Title>
        <Content variant="body1">
          Jeżeli Pacjent nie zgłosi się na wizytę, mimo wcześniejszego jej
          potwierdzenia, Lekarz ma prawo anulować kolejne umówione terminy i
          odmówić zapisania Pacjenta na następną wizytę.
        </Content>
        <Title variant="h5" component="h2">
          10. Konsekwencje niezgłoszenia się na wizytę
        </Title>
        <Content variant="body1">
          Po dwukrotnym niezgłoszeniu się na wizytę, mimo wcześniejszego jej
          potwierdzenia, nasz system elektroniczny uniemożliwi ponowną
          rejestrację Pacjenta.
        </Content>
        <Title variant="h5" component="h2">
          11. Aktualizacja danych osobowych
        </Title>
        <Content variant="body1">
          Prosimy wszystkich Pacjentów o poinformowanie Rejestracji o
          ewentualnej zmianie numeru telefonu lub innych danych osobowych. W
          przeciwnym razie, nie dojdzie do Państwa wiadomość SMS z linkiem, oraz
          Rejestracja nie będzie mogła się z Państwem skontaktować w celu
          potwierdzenia wizyty.
        </Content>
        <Title variant="h5" component="h2">
          12. Obecność rodzica/opiekuna
        </Title>
        <Content variant="body1">
          W trakcie przyjmowania Pacjentów nieletnich, Rodzic/Opiekun ma
          obowiązek być obecny przez cały czas trwania wizyty.
        </Content>
        <Title variant="h5" component="h2">
          13. Opóźnienia w przyjmowaniu Pacjentów
        </Title>
        <Content variant="body1">
          Szanujemy czas naszych Pacjentów, dlatego wizyty są planowane tak, aby
          nie występowały opóźnienia w przyjmowaniu Pacjentów. Niestety, mimo
          naszej organizacji, zdarzyć się mogą nieprzewidziane sytuacje, np.
          niektóre zabiegi mogą ulec przedłużeniu. Jeśli się tak zdarzy, z góry
          przepraszamy i prosimy o zrozumienie, gdyż każdemu z Państwa staramy
          się pomóc w jak najlepszy sposób i poświęcić tyle czasu i uwagi, ile
          wymaga dany przypadek.
        </Content>
      </Container>
      <Footer />
    </>
  )
}

export default ClinicPolicy
