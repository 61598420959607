import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import {
  CardActionArea,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

export default function ActionAreaCardMobile({ title, content, img, alt }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const showButtonIfTextShorterThan = (text, maxCharacters) => {
    return text.length > maxCharacters
  }

  return (
    <Card
      sx={{
        width: '200px',
        height: '400px',
        marginBottom: 2,
      }}
    >
      <CardActionArea
        sx={{
          cursor: 'auto',
        }}
      >
        {img && <CardMedia component="img" height="100" img={img} alt={alt} />}
        <CardContent
          sx={{
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            minHeight: '300px',
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {content.substr(0, 200) + '...'}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            alignSelf: 'center',
          }}
        >
          {showButtonIfTextShorterThan(content, 200) && (
            <Button onClick={handleClickOpen}>Wiecej</Button>
          )}
        </CardActions>
      </CardActionArea>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
