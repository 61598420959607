import React from 'react'
import { Grid, Typography, Box, Container } from '@mui/material'
import PhoneNumber from '../components/PhoneNumber'

const InformationMobile = () => {
  return (
    <Container
      sx={{
        marginTop: 1,
      }}
    >
      <Grid
        direction="column"
        spacing={1}
        container
        sx={{
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Grid item>
          <Box
            sx={{
              marginBottom: 1,
              backgroundColor: 'rgba(7, 115, 248, 0.9 )',
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdrop: 'blur( 15px )',
              WebkitBackdropFilter: 'blur( 15px )',
              padding: 2,
              borderRadius: 4,
              color: '#fff',
            }}
          >
            <Typography variant="subtitle2" sx={{ background: 'none' }}>
              Szanowni pacjenci! <br />
              Informujemy, że w dniu 16.08.2024 gabinety będą nieczynne
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              padding: 2,
              borderRadius: 4,
              color: '#fff',
              background: 'rgba( 255, 255, 255, 0.45 )',
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdropFilter: 'blur( 10px )',
              WebkitBackdropFilter: 'blur( 10px )',

              border: '1px solid rgba( 255, 255, 255, 0.18 )',
            }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ color: 'secondary.main' }}
            >
              W celu rezerwacji wizyty prosimy dzwonić pod numer <br />
              <PhoneNumber />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default InformationMobile
