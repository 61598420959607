import { Button } from '@mui/material'
import React from 'react'
import logoMobile from '../assets/logo-mobile.png'
import logoDesktop from '../assets/logo.png'
import { useWindowWidth } from '../helpers/WindowWidth'

const Logo = () => {
  const logo = useWindowWidth() >= 650 ? logoDesktop : logoMobile

  return (
    <Button>
      <img style={{ width: '60%' }} src={logo} alt="Logo" />
    </Button>
  )
}

export default Logo
