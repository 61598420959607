import React from 'react'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Grid, Container, Typography, TableBody } from '@mui/material'
import offer from '../../data/offer.json'
import { v4 as uuidv4 } from 'uuid'

const PricingList = () => {
  const styledTableHead = (title) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={3}>
            <Typography
              variant="h5"
              sx={{ marginTop: 3, color: 'secondary.main' }}
            >
              {title}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const TableRowContent = (data) => {
    return data.map((item) => {
      return (
        <TableBody key={uuidv4()}>
          <TableRow hover key={uuidv4()}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell align="right">
              {checkIfStrIsPrice(item.price)
                ? item.price && `${item.price} PLN`
                : item.price}
            </TableCell>
          </TableRow>
        </TableBody>
      )
    })
  }

  // This method will check if given value has any digit, if yes return true.
  const checkIfStrIsPrice = (str) => {
    return /\d/.test(str)
  }

  return (
    <Container>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center', marginTop: 4 }}
      >
        <Grid item xs={12} md={6}>
          <Table size="small" className="pricingTable">
            {styledTableHead('Diagnostyka i radiologia')}
            {TableRowContent(offer?.type?.diagnostyka_i_radiologia)}

            {styledTableHead('Stomatologia zachowawcza')}
            {TableRowContent(offer?.type?.stomatologia_zachowawcza)}

            {styledTableHead('Endodoncja')}
            {TableRowContent(offer?.type?.endodoncja)}

            {styledTableHead('Stomatologia dziecięca')}
            {TableRowContent(offer?.type?.stomatologia_dziecięca)}

            {styledTableHead('Chirurgia stomatologiczna')}
            {TableRowContent(offer?.type?.chirurgia_stomatologiczna)}
          </Table>
        </Grid>

        <Grid item xs={12} md={6}>
          <Table size="small" className="pricingTable">
            {styledTableHead('Implantologia')}
            {TableRowContent(offer?.type?.implantologia)}

            {styledTableHead('Protetyka')}
            {TableRowContent(offer?.type?.protetyka)}

            {styledTableHead('Periodontologia')}
            {TableRowContent(offer?.type?.periodontologia)}

            {styledTableHead('Stomatologia estetyczna')}
            {TableRowContent(offer?.type?.stomatologia_estetyczna)}

            {styledTableHead('Ortodoncja')}
            {TableRowContent(offer?.type?.ortodoncja)}

            {styledTableHead('Toksyna Botulinowa - Botox')}
            {TableRowContent(offer?.type?.toksyna_botulinowa)}
          </Table>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PricingList
