import React from 'react'
import { Paper, Typography, Container, Stack } from '@mui/material'
import { useWindowWidth } from '../helpers/WindowWidth'
import vector from '../assets/vector-paro.png'
import laser from '../assets/laser_K2_Mobile_Hulaser.jpg'
import wirówka from '../assets/wirowka-zenithlab.jpg'
import mikroskop from '../assets/mikroskop-zeiss.jpg'
import lampa from '../assets/lampa-beyond.jpg'
import tomograf from '../assets/tomograf-zdjecie.jpg'

const dentistTools = [
  {
    name: 'Vector Paro',
    description:
      'Profilaktyka oraz leczenie choroby przyzębia i stanów zapalnych wokól implantów.',
    img: vector,
    alt: 'Vector Paro',
  },
  {
    name: 'Stożkowa tomografia komputerowa (CBCT)',
    description:
      'Precyzyjne, trójwymiarowe obrazowanie struktur szczęki, żuchwy i ich okolic.',
    img: tomograf,
    alt: 'Stożkowa tomografia komputerowa (CBCT)',
  },
  {
    name: 'Mikroskop',
    description: 'Powiększenie i ulepszenie widoczności podczas leczenia.',
    img: mikroskop,
    alt: 'Mikroskop',
  },
  {
    name: 'Wirówka specjalistyczna do osocza PRP/PRF',
    description: 'Przyspieszenie gojenia ran poekstrakcyjnych.',
    img: wirówka,
    alt: 'Wirówka specjalistyczna do osocza PRP/PRF',
  },
  {
    name: 'Laser diodowy',
    description:
      'Zminimalizowanie dolegliwości bólowych i krwawienia podczas zabiegów chirurgicznych oraz szybsze gojenie ran.',
    img: laser,
    alt: 'Laser diodowy',
  },
  {
    name: 'Lampa Beyond',
    description: 'Jednowizytowe wybielanie zębów.',
    img: lampa,
    alt: 'Lampa Beyond',
  },
]

const Tools = () => {
  const paperWidth = useWindowWidth() >= 900 ? 300 : 200
  return (
    <Container maxWidth="false" id="offer">
      <Container
        maxWidth="false"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <Stack
          className="scroll-container"
          direction="row"
          sx={{
            overflow: 'scroll',
          }}
        >
          {dentistTools.map((tool) => {
            return (
              <Paper
                key={tool.name}
                elevation={2}
                sx={{
                  border: '4px solid #fff',
                  textAlign: 'center',
                  minheight: 60,
                  lineHeight: '60px',
                  margin: 1,
                  backgroundColor: '#fff',
                  minWidth: paperWidth,
                  width: paperWidth,
                  overflow: 'hidden',
                }}
              >
                <img src={tool.img} alt={tool.alt} className="dupa" />
                <Typography
                  variant="h6"
                  sx={{
                    color: 'secondary.main',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {tool.name}
                </Typography>
                <Typography
                  align="center"
                  gutterBottom
                  variant="subtitle1"
                  sx={{
                    color: 'rgb(100, 110, 115)',
                  }}
                >
                  {tool.description}
                </Typography>
              </Paper>
            )
          })}
        </Stack>
      </Container>
    </Container>
  )
}

export default Tools
